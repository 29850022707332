import './App.css';
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { Home, Reminders } from "./pages";
import { Keto, Mindfulness, PublicHealth, Crusty, CrustyCondensed } from "./writing";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      {/* Mindfulness */}
      <Route path="/writing/making-mindfulness-your-default" element={<Mindfulness />} />
      {/* Keto and redirect */}
      <Route path="/writing/keto" element={<Keto />} />
      <Route path="/writing/case-for-normalizing-keto" element={<Navigate to="/writing/keto" />} />
      {/* Public health and redirect */}
      <Route path="/writing/personal-failure-public-health" element={<PublicHealth />} />
      <Route path="/writing/cargo-cults-supply-chains-personal-failure-public-health" element={<Navigate to="/writing/personal-failure-public-health" />} />
      {/* Crusty and redirects */}
      <Route path="/writing/woke-vs-crusty" element={<Crusty />} />
      <Route path="/writing/woke-vs-crusty-1minute" element={<CrustyCondensed />} />
      <Route path="/writing/woke-vs-altwoke" element={<Navigate to="/writing/woke-vs-crusty" />} />
      <Route path="/writing/woke-vs-altwoke-condensed" element={<Navigate to="/writing/woke-vs-crusty-1minute" />} />
      <Route path="/writing/woke-vs-crusty-condensed" element={<Navigate to="/writing/woke-vs-crusty-1minute" />} />
      <Route path="/reminders" element={<Reminders />} />
      <Route path="/*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default App;
