import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Button } from "@mui/material";
import '../App.css';

import { remindersList } from "../data/RemindersData";

const remindersPerPage = 9;


export const Reminders = ({}) => {
  const [shownReminders, setShownReminders] = useState(new Set());
  const [currentReminders, setCurrentReminders] = useState([]);

  // Things that should happen on page load
  useEffect(() => {
    // Set the page title
    document.title = 'Reminders';


    // Initial load of reminders
    const initialReminders = getUniqueReminders();
    //@ts-ignore
    setCurrentReminders(initialReminders);
    //@ts-ignore
    setShownReminders(prev => new Set([...prev, ...initialReminders]));


    // Reload the page every time it's refocused
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        // Page has become visible (refocused), reload the page
        window.location.reload();
      }
    };
    // Attach event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  // Function to get unique random reminders
  const getUniqueReminders = () => {
    const availableReminders = remindersList.filter(reminder => !shownReminders.has(reminder));
    
    // If we've shown all reminders, reset the shown reminders
    if (availableReminders.length < remindersPerPage) {
      setShownReminders(new Set());
      return getRandomReminders(remindersList, remindersPerPage);
    }
    
    return getRandomReminders(availableReminders, remindersPerPage);
  };

  // Helper function to get random reminders
  const getRandomReminders = (sourceReminders:any, count:number) => {
    const selectedReminders = [];
    const tempReminders = [...sourceReminders];
    
    for (let i = 0; i < count && tempReminders.length > 0; i++) {
      const randomIndex = Math.floor(Math.random() * tempReminders.length);
      const reminder = tempReminders.splice(randomIndex, 1)[0];
      selectedReminders.push(reminder);
    }
    
    return selectedReminders;
  };

  // Handle next reminders button
  const handleNextReminders = () => {
    const newReminders = getUniqueReminders();
    //@ts-ignore
    setCurrentReminders(newReminders);
    //@ts-ignore
    setShownReminders(prev => new Set([...prev, ...newReminders]));
    
    // Scroll to top
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <div className="reminder-page">
      <Helmet>
        <meta name="robots" content="noindex" /> 
      </Helmet>
      <div>
        {currentReminders.map((reminder, index) => (
          <div key={index} className="reminder-container">
              <span className="reminder-text reminder-number">
                {index + 1}.
              </span>
              <span className="reminder-text reminder-content">
                <mark className="reminder-content-highlight">{reminder}</mark>
              </span>
          </div>
        ))}
      </div>
      
      <div className="flex justify-center">
        <Button 
          onClick={handleNextReminders} 
          className="w-full max-w-xs"
        >
          More
        </Button>
      </div>
    </div>
  )
}
